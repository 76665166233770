<template>
	<section class="section-wrapper" v-background="backgroundOverlay">
        <grid-template cols="2">
			<div v-animate="`slideLeft`">
				<cms-multi-section class="section-elements" :value="`${prefix}.section_content`"/>
				<base-button icon="info" class="download-button" @click="download(value.catalog_file)">
					{{langs.catalog}}
				</base-button>
			</div>
            <cms-image
				class="catalog-img"
				lazy
				:src="value.catalog_image"
				:base="{width: 300}"
                :md="{width:380}"
				:lg="{width: 500, height: 420}"
				:xl="{width: 500, height: 420}"
                :xxl="{width: 690, height: 500}"
                v-animate="`opacity`"
			/>
        </grid-template>
	</section>
</template>
<script>
import path from 'path'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection.vue'
import GridTemplate from '~/website/front/templates/GridTemplate.vue'
export default {
	props: {
		prefix: String,
		value: Object
	},
	components: {
		CmsMultiSection,
		GridTemplate
	},
	computed: {
		backgroundOverlay () {
			const path = '/products/product-section-bg.jpg'
			return {
				src: path,
				breakpoints: {
					base: { width: 720, height: 650 },
					lg: { width: 825, height: 451 },
					xl: { width: 1920, height: 515 }
				}
			}
		},
		langs () {
			return this.$app.translator.get('products')
		}
	},
	methods: {
		async download (item) {
			const service = this.$app.getService('rext')
			const bufferData = await service.getFile(item.path)

			const fileUrl = window.URL.createObjectURL(new Blob([bufferData]))
			const fileLink = document.createElement('a')
			const fileName = path.basename(decodeURI(item.path))

			fileLink.href = fileUrl
			fileLink.setAttribute('download', fileName)
			document.body.appendChild(fileLink)

			fileLink.click()
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
    position: relative;
	padding: 4rem 0;
    z-index: 1;
	@include media-breakpoint-up(xl) {
		padding: 8rem 0;
	}
    &::before {
        content: '';
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height: 100%;
        background: rgba($secondary, .95);
        z-index: -1;
    }
    ::v-deep .grid-wrapper {
        grid-template-columns: repeat(1, 1fr);
        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .section-elements {
        ::v-deep .text-content {
            color: $white;
        }
        ::v-deep .subtitle {
            color: $white;
            font-weight: bold;
            @include media-breakpoint-up(xl){
                font-size: 4.4rem;
            }
        }
    }
    .catalog-img {
        bottom: 0;
        right: 0;
        position: absolute;
        display: flex;
        opacity: .2;
        z-index: -1;
        @include media-breakpoint-up(md) {
        display: flex;
        bottom: 0;
        left: calc(45% + 5rem);
        opacity: 1;
        }
        ::v-deep .base-image {
            display: flex;
        }
    }
	.download-button {
		margin-top: 3rem;

		@include media-breakpoint-up(xl) {
			margin-top: 6rem;
		}

	}
}
</style>
